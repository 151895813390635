import { qsRequired } from '@/scripts/functions'
import { UcoastEl } from '@/scripts/core/UcoastEl'
import { type uCoastWindow } from '@/scripts/setup'
import { type KlaviyoPopup } from '@/scripts/content/modal'

declare let window: uCoastWindow

export class CustomerPrivacy extends UcoastEl {
	static htmlSelector = 'customer-privacy'
	elements: {
		bar: HTMLElement
		accept: HTMLButtonElement
		decline: HTMLButtonElement
	}
	constructor() {
		super()
		this.elements = {
			bar: qsRequired<HTMLElement>('[data-uc-privacy-bar]', this),
			accept: qsRequired('[data-uc-privacy-accept]', this),
			decline: qsRequired('[data-uc-privacy-decline]', this),
		}
		this.elements.accept.addEventListener('click', this.onAccept.bind(this))
		this.elements.decline.addEventListener('click', this.onDecline.bind(this))
	}

	initializeBar(error?: Error | Error[]) {
		if (error) {
			throw error
		}
		if (window.Shopify.customerPrivacy) {
			const shouldShowGDPRBanner = window.Shopify.customerPrivacy.shouldShowGDPRBanner()
			const shouldShowCCPABanner = window.Shopify.customerPrivacy.shouldShowCCPABanner()
			const userCanBeTracked = window.Shopify.customerPrivacy.userCanBeTracked()
			const userTrackingConsent = window.Shopify.customerPrivacy.getTrackingConsent()
			const shouldShowBanner =
				window.demoMode === true ||
				(!userCanBeTracked &&
					userTrackingConsent === 'no_interaction' &&
					(shouldShowGDPRBanner || shouldShowCCPABanner))
			if (shouldShowBanner && this.elements.bar.getAttribute('aria-hidden') === 'true') {
				this.elements.bar.removeAttribute('style')
				window.setTimeout(() => {
					this.elements.bar.setAttribute('aria-hidden', 'false')
				}, 1)
			}
		}
	}

	onAccept(event: Event) {
		event.preventDefault()
		if (window.Shopify.customerPrivacy) {
			window.Shopify.customerPrivacy.setTrackingConsent(true, () => {
				this.elements.bar.setAttribute('aria-hidden', 'true')
				if (window.klaviyoPopupWaitingForConsent) {
					window.klaviyoPopupWaitingForConsent = false
					window.demoMode = false
					const klaviyoPopup = qsRequired<KlaviyoPopup>('klaviyo-popup')
					klaviyoPopup.initializePopup()
				}
			})
		}
	}

	onDecline(event: Event) {
		event.preventDefault()
		if (window.Shopify.customerPrivacy) {
			window.Shopify.customerPrivacy.setTrackingConsent(false, () => {
				this.elements.bar.setAttribute('aria-hidden', 'true')
			})
		}
	}

	override connectedCallback() {
		super.connectedCallback()
		if (window.Shopify.loadFeatures) {
			window.Shopify.loadFeatures(
				[
					{
						name: 'consent-tracking-api',
						version: '0.1',
					},
				],
				this.initializeBar.bind(this)
			)
		}
	}
}
