import { SearchForm } from '@/scripts/forms/search-form'
import { ShareButton } from '@/scripts/forms/share'
import { Modal, ModalOpener, NotifyMe } from '@/scripts/content/modal'
import { QuantityInput, VariantSelects } from '@/scripts/product/product-options'
import { ProductRecommendations } from '@/scripts/product/product-recommendations'
import { QuickAddModal } from '@/scripts/product/quick-add'
import { ProductModal } from '@/scripts/product/product-modal'
import { ProductInfo } from '@/scripts/product/product-info'
import { ProductForm } from '@/scripts/product/product-form'
import { initShopify, uCoastWindow } from '@/scripts/setup'
import { safeDefineElement } from '@/scripts/functions'
import { PredictiveSearch } from '@/scripts/forms/predictive-search'
import { CustomerPrivacy } from '@/scripts/forms/customer-privacy'
import { KlaviyoPopup } from '@/scripts/content/modal'
import { KlaviyoForm, KlaviyoOosForm } from '@/scripts/forms/klaviyo-form'
//import { WishlistCallout } from '@/scripts/product/wishlist-callout'

declare let window: uCoastWindow

function initialize() {
	// Shopify window stuff
	if (typeof window.Shopify == 'undefined') {
		window.Shopify = initShopify()
	}
	// define custom elements
	safeDefineElement(PredictiveSearch)
	safeDefineElement(SearchForm)
	safeDefineElement(ShareButton)
	safeDefineElement(Modal)
	safeDefineElement(ModalOpener)
	safeDefineElement(QuantityInput)
	safeDefineElement(VariantSelects)
	safeDefineElement(ProductForm)
	safeDefineElement(ProductInfo)
	safeDefineElement(ProductModal)
	safeDefineElement(QuickAddModal)
	safeDefineElement(ProductRecommendations)
	safeDefineElement(CustomerPrivacy)
	safeDefineElement(KlaviyoForm)
	safeDefineElement(KlaviyoOosForm)
	safeDefineElement(KlaviyoPopup)
	safeDefineElement(NotifyMe)
	//safeDefineElement(WishlistCallout)
}

if (document.readyState === 'loading') {
	document.addEventListener('DOMContentLoaded', initialize)
} else {
	initialize()
}

export const shopify = window.Shopify
